import React from "react";
import styles from "./Cards.module.css";
import { TiThLargeOutline } from "react-icons/ti";
import { BiCollection } from "react-icons/bi";
import { Md30Fps } from "react-icons/md";
import { PiMountainsBold } from "react-icons/pi";
import { CgAwards } from "react-icons/cg";
import { PiUsersBold } from "react-icons/pi";
import { motion } from "framer-motion";

export default function Cards() {
  const cardsData = [
    {
      icon: <Md30Fps />,
      title: "30 years of Fashion Legacy",
      body: 'Our 30 years of fashion journey has equipped us with the right knowledge and "know how" to guide you for making a better and more fashion conscious choice. So that you look your absolute best for every major occasion of your life.',
    },
    {
      icon: <BiCollection />,
      title: "Collection Curated by Experts",
      body: "Our collection is handpicked to suite your style and preferences. Which reduces the strain on you as a customer, but puts it onto our fashion experts. Nonetheless we would happily accept this trade-off, if it gives you the right confidence to express yourself for every major milestone of your life.",
    },
    {
      icon: <TiThLargeOutline />,
      title: "Home to 30+ brands",
      body: "Our store is home to 30+ brands, All of which are connoisseurs in their respective categories. This Multi-faceted diversification gives you the freedom to choose from the best in the industry and waste no time into looking out for substandard options.",
    },
    {
      icon: <PiMountainsBold />,
      title: "First-Ever MBO (Multi-Branded Outlet) of Kashmir",
      body: "We are proud to take the honour of calling ourselves the first ever MBO for men's fashion in the Whole of Kashmir.",
    },
    {
      icon: <CgAwards />,
      title: "Recipient of Honorary Awards & Accolades",
      body: 'Our Awards and accolades are a testament to our rich legacy of quality and trustworthiness which has remained constant throughout this journey and would continue to be for as long as the "Zeal sprit is alive". Awards like Levi\'s highest Growth Award – North India 2019, Indian Terrain Brand of Choice Award and Being human Authorized retailer of the year award 2017 are among the many trust batches we have accumulated over the years.',
    },
    {
      icon: <PiUsersBold />,
      title: "Highly Experienced staff",
      body: "Our staff speaks the language of fashion, so rest assured you are in safe hands when it comes to making the right fashion choices. Our team of highly experienced staff ensures that you don't leave without a fond memory of a great shopping experience coupled with an un-matched service blend.",
    },
  ];
  return (
    <section className="py-[100px]">
      <div className="container mx-auto px-3">
        <div className="flex flex-wrap">
          <div className="w-full text-center mb-10">
            <motion.h3
              initial={{ y: "-50px", opacity: 0 }}
              whileInView={{ y: "0px", opacity: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="pre-heading"
            >
              Why Choose Us
            </motion.h3>
            <motion.h2
              initial={{ y: "-50px", opacity: 0 }}
              whileInView={{ y: "0px", opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              viewport={{ once: true }}
              className="heading"
            >
              our 30 years of fashion journey
              <br /> has equipped us with the right knowledge
            </motion.h2>
          </div>
          {cardsData.map((card, i) => (
            <motion.div
              initial={{ y: "-50px", opacity: 0 }}
              whileInView={{ y: "0px", opacity: 1 }}
              transition={{ duration: 0.2, delay: (i + 1) / 10 }}
              viewport={{ once: true }}
              className={styles.card + " w-full md:w-1/2 xl:w-1/3"}
              key={i}
            >
              <div>
                <span>0{i + 1}</span>
                <div>{card.icon}</div>
                <h3 className="text-xl font-bold mb-6">{card.title}</h3>
                <p>{card.body}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
