import React from "react";
import styles from "./OneStop.module.css";
import { motion } from "framer-motion";

export default function OneStop() {
  return (
    <section className="py-[100px]">
      <div className="container mx-auto px-3">
        <motion.div
          className="flex flex-wrap items-center justify-center mx-auto "
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
        >
          <div className="w-full lg:w-[80%]">
            <div className={styles.box + " py-10 px-2"}>
              <div className="flex flex-wrap gap-y-2 justify-center items-center">
                <div className="w-full lg:w-[50%] pl-[40px] text-center lg:text-left">
                  <h4 className="pre-heading">Easy Shopping</h4>
                  <h2 className="heading">
                    One Stop Shop for your brands shopping
                  </h2>
                </div>
                <div className={"w-full lg:w-[50%] " + styles.logos_wrapper}>
                  <div className={styles.logos}>
                    <motion.img
                      initial={{
                        opacity: 0,
                        transform: "translate(120px, 120px)",
                      }}
                      whileInView={{ opacity: 1, transform: "translate(0, 0)" }}
                      transition={{
                        ease: "easeOut",
                        duration: 0.5,
                        delay: 1.5,
                      }}
                      viewport={{ once: true }}
                      src={"./images/brands/uspolo.png"}
                      alt="US Polo logo | Zeal Clothing"
                    />
                    <motion.img
                      initial={{ opacity: 0, transform: "translate(0, 120px)" }}
                      whileInView={{ opacity: 1, transform: "translate(0, 0)" }}
                      transition={{
                        ease: "easeOut",
                        duration: 0.5,
                        delay: 1.6,
                      }}
                      viewport={{ once: true }}
                      src={"./images/brands/levis.png"}
                      alt="Levi's Logo | Zeal Clothing"
                    />
                    <motion.img
                      initial={{
                        opacity: 0,
                        transform: "translate(-120px, 120px)",
                      }}
                      whileInView={{ opacity: 1, transform: "translate(0, 0)" }}
                      transition={{
                        ease: "easeOut",
                        duration: 0.5,
                        delay: 1.7,
                      }}
                      viewport={{ once: true }}
                      src={"./images/brands/pepejeans.jpeg"}
                      alt="Pepe Jeans Logo | Zeal Clothing"
                    />
                    <motion.img
                      initial={{ opacity: 0, transform: "translate(120px, 0)" }}
                      whileInView={{ opacity: 1, transform: "translate(0, 0)" }}
                      transition={{
                        ease: "easeOut",
                        duration: 0.5,
                        delay: 1.8,
                      }}
                      viewport={{ once: true }}
                      src={"./images/brands/beinghuman.jpeg"}
                      alt="Being Human Logo | Zeal Clothing"
                    />
                    <img
                      className={styles.zeal}
                      src={"./images/brands/zeal.png"}
                      alt="Zeal Logo | Zeal Clothing"
                    />
                    <motion.img
                      initial={{
                        opacity: 0,
                        transform: "translate(-120px, 0px)",
                      }}
                      whileInView={{ opacity: 1, transform: "translate(0, 0)" }}
                      transition={{
                        ease: "easeOut",
                        duration: 0.5,
                        delay: 1.9,
                      }}
                      viewport={{ once: true }}
                      src={"./images/brands/puma.png"}
                      alt="Puma Logo | Zeal Clothing"
                    />
                    <motion.img
                      initial={{
                        opacity: 0,
                        transform: "translate(120px, -120px)",
                      }}
                      whileInView={{ opacity: 1, transform: "translate(0, 0)" }}
                      transition={{
                        ease: "easeOut",
                        duration: 0.5,
                        delay: 2.0,
                      }}
                      viewport={{ once: true }}
                      src={"./images/brands/jackandjones.png"}
                      alt="Jack & Jones Logo | Zeal Clothing"
                    />
                    <motion.img
                      initial={{
                        opacity: 0,
                        transform: "translate(0, -120px)",
                      }}
                      whileInView={{ opacity: 1, transform: "translate(0, 0)" }}
                      transition={{
                        ease: "easeOut",
                        duration: 0.5,
                        delay: 2.1,
                      }}
                      viewport={{ once: true }}
                      src={"./images/brands/ucb.png"}
                      alt="UCB Logo | Zeal Clothing"
                    />
                    <motion.img
                      initial={{
                        opacity: 0,
                        transform: "translate(-120px, -120px)",
                      }}
                      whileInView={{ opacity: 1, transform: "translate(0, 0)" }}
                      viewport={{ once: true }}
                      transition={{
                        ease: "easeOut",
                        duration: 0.5,
                        delay: 2.2,
                      }}
                      src={"./images/brands/arrow.png"}
                      alt="Arrow Logo | Zeal Clothing"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}
