import React, { useEffect, useState } from "react";
import styles from "./Hero.module.css";
import CustomButton from "../CustomButton/CustomButton";
import { motion } from "framer-motion";

export default function Hero() {
  const brands = [
    "Style",
    "Sensation",
    "Thoughtfulness",
    "Vigour",
    "Enthusiasm",
    "& More",
  ];
  const [dynamicText, setDynamicText] = useState("");
  const [brandIndex, setBrandIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  let loaded = false;
  useEffect(() => {
    if (!loaded) {
      if (brandIndex < brands.length) {
        if (charIndex < brands[brandIndex].length) {
          setTimeout(() => {
            setDynamicText(
              (prev) => prev + brands[brandIndex].charAt(charIndex)
            );
            setCharIndex(charIndex + 1);
          }, 150);
        } else {
          setTimeout(() => {
            setDynamicText("");
            setCharIndex(0);
            setBrandIndex(brandIndex + 1);
          }, 1000);
        }
      } else {
        setTimeout(() => {
          setBrandIndex(0);
          setCharIndex(0);
          setDynamicText("");
        }, 1000);
      }
    }
    loaded = true;
  }, [charIndex, brandIndex, brands]);
  return (
    <div className={`pt-[150px] pb-[100px] ${styles.hero} px-3 sm:px-10`}>
      <div className={`container mx-auto`}>
        <div className="flex flex-wrap items-center">
          <div className="w-full lg:w-[50%] mb-20 lg:mb-0 text-center lg:text-left">
            <div className={styles["hero-text-wrapper"]}>
              <motion.h4
                initial={{ x: "-200px" }}
                whileInView={{ x: "0px" }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                }}
                className="pre-heading"
              >
                Zeal Clothing
              </motion.h4>
              <motion.h1
                initial={{ x: "-200px", opacity: 0 }}
                whileInView={{ x: "0px", opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                Craft your Fashion Story Full of
                <br />
                <span className={styles.typewriter}>{dynamicText}</span>
                <span className={styles.type}>|</span>
              </motion.h1>
              <motion.p
                className="mb-10"
                initial={{ x: "-200px", opacity: 0 }}
                whileInView={{ x: "0px", opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                Explore the latest and most trendy collection from all the major
                brands of India all under one roof , Handpicked by experts to
                get you the premier experience no matter what the occasion. We
                are Home to 30 + brands .{" "}
              </motion.p>
              <motion.div
                initial={{ x: "-200px", opacity: 0 }}
                whileInView={{ x: "0px", opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.6 }}
                className="mx-auto"
              >
                <CustomButton link="stores" title="Checkout our Stores" />
              </motion.div>
            </div>
          </div>
          <div className="w-full lg:w-[50%]">
            <div className="max-w-[500px] mx-auto relative lg:ml-[50px]">
              <motion.img
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                transition={{
                  ease: "easeOut",
                  duration: 0.5,
                }}
                viewport={{ once: true }}
                src={"./images/bg-gradient.jpeg"}
                alt=""
                className="rounded-lg"
              />
              <motion.img
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                transition={{
                  ease: "easeOut",
                  duration: 0.5,
                  delay: 0.5,
                }}
                viewport={{ once: true }}
                src={"./images/IMG_0196.jpg"}
                alt=""
                className="max-w-[200px] sm:max-w-[290px] absolute top-[-20px] left-[-10px] sm:top-[50px] sm:left-[-40px] rounded-md"
              />
              <motion.img
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                transition={{
                  ease: "easeOut",
                  duration: 0.5,
                  delay: 1,
                }}
                viewport={{ once: true }}
                src={"./images/IMG_6070.webp"}
                alt=""
                className="max-w-[200px] sm:max-w-[270px] absolute bottom-[-40px] right-[-10px] sm:bottom-[40px] sm:right-[-40px] rounded-md"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
