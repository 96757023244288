import React from "react";
import styles from "./Header.module.css";
import { Link } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
export default function Header() {
  return (
    <nav className={styles.navbar}>
      <div className="container mx-auto">
        <div className="flex flex-wrap justify-between items-center">
          <div className={styles.logo + " col-md-auto"}>
            <Link to="/" className="cursor-hover">
              <img src={"/logo.png"} alt="" style={{ maxWidth: "100px" }} />
            </Link>
          </div>
          <div className="flex items-center justify-end">
            <ul className={styles.navlinks}>
              <li>
                <Link to="/" className="cursor-hover">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="cursor-hover">
                  About
                </Link>
              </li>
              <li>
                <Link to="/stores" className="cursor-hover">
                  Our Stores
                </Link>
              </li>
              <li>
                <CustomButton title="Contact" link="contact" />
              </li>
            </ul>
            <div className={styles.header_icons}>
              <div className={styles.toggle_mode}></div>
              <HamburgerMenu />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
