import React from "react";
import CustomButton from "../CustomButton/CustomButton";
import { motion } from "framer-motion";

export default function Intro() {
  return (
    <section className="py-[100px]">
      <div className="container mx-auto px-3">
        <div className="flex flex-wrap items-center gap-y-5">
          <div className="w-full md:w-[50%]">
            <motion.div
              className="max-w-[500px] mx-auto relative pr-0 md:pr-2"
              initial={{ x: "-200px" }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
            >
              <img
                src={"./images/IMG_2238.JPG"}
                alt=""
                className="rounded-lg"
              />
            </motion.div>
          </div>
          <div className="w-full md:w-[50%]">
            <motion.h4
              className="pre-heading"
              initial={{ x: "200px", opacity: 0 }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeOut",
                duration: 0.5,
                delay: 0.1,
              }}
            >
              Discover the Latest Trends
            </motion.h4>
            <motion.h2
              className="heading mb-10 border-b border-b-gray-700 pb-10"
              initial={{ x: "200px", opacity: 0 }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeOut",
                duration: 0.5,
                delay: 0.2,
              }}
            >
              Handpicked Collections from Over 30 Top Indian Brands, All Under
              One Roof
            </motion.h2>
            <motion.p
              className="mb-10"
              initial={{ x: "200px", opacity: 0 }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeOut",
                duration: 0.5,
                delay: 0.3,
              }}
            >
              {`Established in 1995, Zeal began by offering mid-segment brands at
              a time when Kashmir was experiencing significant turmoil. Seeing
              an opportunity to cater to the burgeoning demands of Kashmir's
              fashion-conscious population, who were seeking to upgrade from
              ordinary attire to a more sophisticated lifestyle, Zeal took a
              bold step. Despite the nascent state of fashion trends at the
              time, the founders foresaw a growing interest in high-quality
              brands and decided to introduce a lineup of major fashion names
              that were unfamiliar to many. This vision allowed Zeal to serve as
              a pioneer in the region, bringing the latest and most sought-after
              styles to the forefront.`}
            </motion.p>
            <motion.div
              initial={{ x: "200px", opacity: 0 }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeOut",
                duration: 0.5,
                delay: 0.3,
              }}
            >
              <CustomButton link="about" title="Learn More" />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}
