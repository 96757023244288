import React from "react";
import styles from "./ContactInfo.module.css";
import { FaFacebookF } from "react-icons/fa6";
import { RiInstagramFill, RiWhatsappFill } from "react-icons/ri";
export default function ContactInfo() {
  return (
    <div className={styles.wrapper}>
      <p>Lambert Lane , Residency Road Lal Chowk Srinagar - 190001 </p>
      <p>Springs Mall , K.P Road Anantnag - Jammu & Kashmir - 192101 </p>
      <p>
        Email: <a href="mailto:zeal707@gmail.com">zeal707@gmail.com</a>
      </p>
      <p>
        Phone: <a href="tel:01942459514">01942459514</a>
      </p>
      <div className={styles.socials}>
        <a
          href="https://www.facebook.com/zealclothing"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.instagram.com/zealclothing"
          target="_blank"
          rel="noreferrer"
        >
          <RiInstagramFill />
        </a>
        <a href="https://wa.me/916006395995" target="_blank" rel="noreferrer">
          <RiWhatsappFill />
        </a>
      </div>
    </div>
  );
}
