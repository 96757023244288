import { motion } from "framer-motion";
import React from "react";

export default function Brands({
  logo,
  title,
  details,
  img,
  isLast = false,
}: {
  logo: string;
  title: string;
  details: string;
  img: string;
  isLast?: boolean;
}) {
  return (
    <section className="pt-[50px]">
      <div
        className={`container px-3 mx-auto mb-[50px] pb-[50px] ${
          isLast ? "" : "border-b border-b-[#fff3]"
        }`}
      >
        <div className="flex flex-wrap items-center gap-y-2">
          <div className="w-full lg:w-3/5 p-0 lg:pr-10">
            <motion.img
              src={`./images/${logo}`}
              alt=""
              className="max-w-[70px] mb-2 brightness-[100]"
              initial={{ x: "-100px", opacity: 0 }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            />
            <motion.h2
              className="heading"
              initial={{ x: "-100px", opacity: 0 }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              {title}
            </motion.h2>
            <motion.p
              initial={{ x: "-100px", opacity: 0 }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              {details}
            </motion.p>
          </div>
          <div className="w-full lg:w-2/5">
            <motion.div
              className="p-5 md:px-20"
              initial={{ x: "100px", opacity: 0 }}
              whileInView={{ x: "0px", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <img
                src={`./images/${img}`}
                alt={`Zeal Clothing | Brand: ${title}`}
                className="rounded-xl"
              />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}
